<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_procurations')}}</b-breadcrumb-item>
      </div>
    </div>

    <b-card no-body>
      <!--<b-card-header class="pb-50">-->
        <!--<h5 class="text-primary">{{$t('label_undecided_mail')}}</h5>-->
      <!--</b-card-header>-->
      <b-card-body>
        <b-row>

          <b-col cols="12">
            <b-row class="">
              <b-col
                      cols="12"
                      md="3"
                      class=" mb-1 date-range-col"
              >
                <label>{{ $t('label_date') }}</label>

                <div class="date-range-filter">
                  <custom-date-picker
                          class="date--picker no-label"
                          :locale="$store.state.appConfig.locale"
                          :placeholder="$t('label_DD-MM-YYYY')"
                          :value="filterData.agreement_at_from"
                          @input="filterData.agreement_at_from = $event"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon icon="XIcon" size="14"/>
                    </template>
                  </custom-date-picker>

                  <div class="date-separator"/>

                  <custom-date-picker
                          class="date--picker no-label"
                          :locale="$store.state.appConfig.locale"
                          :placeholder="$t('label_DD-MM-YYYY')"
                          :value="filterData.agreement_at_to"
                          @input="filterData.agreement_at_to = $event"
                  >
                    <template #clear-btn="{ vm }">
                      <feather-icon icon="XIcon" size="14"/>
                    </template>
                  </custom-date-picker>
                </div>

                <!--</b-form-group>-->
              </b-col>

              <b-col cols="12" md="3" class="mb-1">
                <label>{{$t('label_solicitor')}}</label>
                <!--:query-params="{participant_type:'recipient'}"-->
                <infinity-scroll
                        v-model="filterData.solicitor_id"
                        :multiple="false"
                        primary-key="id"
                        url="/solicitor"
                        selectedProp="id"
                        :placeholder="$t('label_solicitor')"

                >
                  <template #label="{item}">{{ item.name  }}</template>

                </infinity-scroll>
              </b-col>
              <b-col cols="12" md="3" class="mb-1">
                <label>{{$t('label_procurations_status')}}</label>
                <v-select
                        class="sort--select"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="filterData.status"
                        :options="activeOptions"
                        :multiple="false"

                        :placeholder="$t('label_procurations_status')"
                        :reduce="val => val.alias"
                >
                  <template v-slot:option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span class="">{{ $t(option.label) }}</span>
                  </template>
                  <template v-slot:selected-option="option">
                    <!--<span :class="option.icon"></span>-->
                    <span >{{ $t(option.label) }}</span>
                  </template>
                  <template v-slot:no-options="option">
                    {{$t('label_no_items')}}
                  </template>
                </v-select>
              </b-col>


            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

          >
            <!--<label>{{ $t('label_show') }}</label>-->
            <v-select
                    v-model="tableData.perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tableData.perPageOptions"
                    :clearable="false"
                    :searchable="false"
                    class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{$t('label_no_items')}}
              </template>
            </v-select>

          </b-col>

        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

<div class="px-2 table-container-wrap">
  <!--sticky-header-->
  <b-table

          :ref="PREFIX+'_TABLE'"
          class="position-relative transited-table"
          :items="tableItems"
          responsive
          no-border-collapse
          :busy="isBusy"
          :fields="columnsDefs"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
  >

    <template #head()="data">
      <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
    </template>

    <template #cell(deal_number)="data">
      <router-link v-if="$can('view', 'deal.tab_details')" :to="{name: 'deal-details', params:{id:data.item.deal_id}}">
        {{data.item.number}}
      </router-link>
      <template v-else>
        {{data.item.number}}
      </template>
    </template>


    <template #cell(solicitor_name)="data">

      <router-link v-if="$can('view', 'solicitor.tab_details')" :to="{ name: 'solicitor-details', params: { id: data.item.solicitor_id } }">
        {{ data.item.solicitor_name }}
      </router-link>
      <template v-else>
        {{ data.item.solicitor_name }}
      </template>

    </template>

    <template #cell(is_termless)="data">
      <span v-if="data.item.is_termless == 1">{{ $t('label_active')}}</span>
      <span v-else-if="data.item.is_termless == 0 && $moment.unix(data.item.date_from_at) < $moment($moment().toDate().toUTCString()) && $moment.unix(data.item.date_to_at) >= $moment($moment().toDate().toUTCString())">{{ $t('label_active')}}</span>
      <span v-else>{{ $t('label_inactive')}}</span>

    </template>



    <template #cell(actions)="data">

      <b-dropdown
              dropleft
              boundary="window"
              variant="link"
              no-caret
              @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
              @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
              :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
          />
        </template>


        <b-dropdown-item
                @click="editedItem = data.item; $bvModal.show(PREFIX + '-modal');">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteItem(data.item.id)">
          <feather-icon icon="TrashIcon"/>
          <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

  </b-table>
  <div class="mx-2 mb-2">
    <b-row>

      <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
      </b-col>
      <!-- Pagination -->
      <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableTotal"
                :per-page="tableData.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
            />
          </template>
        </b-pagination>

      </b-col>

    </b-row>
  </div>
</div>

    </b-card>

    <procurator-modal
            @item-added="refreshDataTable()"
            @item-edited="refreshDataTable()"
            :module-item="false"
            :edited-item="editedItem"
    ></procurator-modal>

  </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  procuratorModal from './../deal/modals/processProcuratorModal'
    import infinityScroll from '@/views/components/infinityScroll'

    import {DEAL_PREFIX as MODULE_PREFIX, PROCURATION_PREFIX as PREFIX, activeOptions} from './../deal/moduleHelper'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
        BCardBody,
            BFormCheckbox,

        BCardHeader,
        BTooltip,
            procuratorModal,
            vSelect,
            infinityScroll
        },

        data() {
            return {
                PREFIX,
                MODULE_PREFIX,

                checkedIds: [],
                editedItem: false,

                selectedColumns:[],

                columnsDefs: [
                    // {label: '#', key: 'counter'},
                    {label: 'label_deal_number', key: 'deal_number'},
                    {label: 'label_solicitor', key: 'solicitor_name'},
                    {label: 'label_date_from', key: 'date_from_at', sortable: false, formatter:  (v) => {
                            return v? formatDate(v,'DD.MM.YYYY', 'unix') : '--'
                        }},
                    {label: 'label_date_till', key: 'date_to_at', sortable: false, formatter:  (v) => {
                            return v? formatDate(v,'DD.MM.YYYY', 'unix') : '--'
                        }},
                    {label: 'label_status', key: 'is_termless'},
                    {label: 'label_action', key: 'actions'}
                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [ 10, 25, 50, 100],
                },
                filterData: {
                    solicitor_id: '',
                    agreement_at_from: '',
                    agreement_at_to: '',
                    status: 'active',
                },
                // sourceOptions:[],
                activeOptions
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            // this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el)=>{
                if(el.key == 'actions' && !this.$can('edit', 'agreement.tab_procurations')){
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', this.PREFIX+'s', {
                    params: {
                        // [this.MODULE_PREFIX+ '_id']: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        procurating_status: this.filterData.status,
                        solicitor_id: this.filterData.solicitor_id,
                        agreement_at_from: this.filterData.agreement_at_from,
                        agreement_at_to: this.filterData.agreement_at_to,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });

            },
            deleteItem(id) {

                    this.confirmDeleting((result) => {
                        this.async('delete', '/'+this.MODULE_PREFIX+'_' + this.PREFIX + 's/' + id , {}, function (resp) {
                            this.refreshDataTable();
                        });
                    });

            },

        },

    }
</script>

